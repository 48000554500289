zoo.controller('addCtrl', ['$scope', '$http', '$timeout', function ($scope, $http, $timeout) {

    //Register all globals
    $scope.sum = 0;
    $scope.hideCart = true;
    $scope.loader = false;
    $scope.user = {};
    $scope.delivery = {};
    $scope.delivery.shipping_prices = {};
    $scope.delivery.free_shipping_prices = {};
    $scope.options = false;
    $scope.show_omniva = true;
    $scope.show_dpd = true;
    $scope.show_venipak = true;
    $scope.reservations = false;
    $scope.total_points = 0;
    $scope.searchTop = null;
    $scope.itemsFound = [];

    var init = function () {
        $http.get('/shop/cart/cart').success(function (data) {
            // $scope.calculate();
            $scope.carts = data;
        });

        $scope.loader = true;
        jQuery('.classShow').attr('style', '');
    };

    //Show empty cart text
    $scope.$watch('hideCart', function () {
        if ($scope.hideCart && $scope.loader) {
            jQuery('.emptyCart').attr('style', '');
        }
        else {
            jQuery('.emptyCart').attr('style', 'display: none;');
        }
    }, true);

    $scope.$watch('searchTop', function() {

        if ($scope.searchTop && $scope.searchTop.length > 3) {

            var dataObject = {
                "type": "products",
                "index": "zoopasaule",
                "from": 0,
                "size": 20,
                "body": {
                    "query": {
                        "query_string" : {
                            "query":    '' + $scope.searchTop + '',
                            "fields": [ "title", "title_en", "title_ru", "code", "small_title", "small_title_ru", "small_title_en" ]
                        }
                    }
                }
            };

            $http.post('https://www.zoopasaule.lv/v2/elastic/search/', dataObject).success(function (data) {

                $timeout(function() {
                   $scope.itemsFound = data.hits.hits;
                });

            }).error(function (data) {
                console.log("Error when searching");
            });

        }

    }, true);

    //Watch for delivery
    $scope.$watch('delivery', function () {
        if ($scope.delivery && $scope.sum) {
            if ($scope.sum > $scope.delivery.free_shipping) {
                $scope.delivery.price = 0.00;
                $scope.shippingISfree = true;
            }
            else {
                $scope.shippingISfree = false;
            }
        }
    }, true);

    //Change shipping price.
    $scope.$watch('checkout_type', function () {

        $scope.delivery.price = $scope.delivery.shipping_prices[$scope.checkout_type];
        $scope.delivery.free_shipping = $scope.delivery.free_shipping_prices[$scope.checkout_type];

    });

    //Watch cart
    $scope.$watch('carts', function () {

        if (typeof($scope.carts) !== "undefined") {
            $scope.calculate();
        }

        if ($scope.carts) {
            if ($scope.carts.length <= 0) {
                $scope.hideCart = true;
            }
            else {
                $scope.hideCart = false;
            }

        }
        else {
            $scope.hideCart = true;
        }

    }, true);

    //Check weight and shipping methods
    $scope.calculateWeightForShipping = function () {

        var total_weight = 0;
        var weight_limit_omniva = 30;
        var weight_limit_dpd = 20;
        var disable_omniva = 0;
        var disable_dpd = 0;

        angular.forEach($scope.carts, function (obj) {
            var pieces = (obj.product.pieces == 0 ? 1 : obj.product.pieces);
            var weight = (obj.product.weight * pieces) * obj.count;
            total_weight = total_weight + weight;
            disable_omniva = disable_omniva + obj.product.disable_omniva;
            disable_dpd = disable_dpd + obj.product.disable_dpd;

        });

        //Disable omniva
        if (weight_limit_omniva <= total_weight || disable_omniva > 0)
            $scope.show_omniva = false;
        else
            $scope.show_omniva = true;

        //Disable dpd
        if (weight_limit_dpd <= total_weight || disable_dpd > 0)
            $scope.show_dpd = false;
        else
            $scope.show_dpd = true;

    };

    //Calculate price
    $scope.calculate = function () {
        $http.get('/shop/cart/calculate/').success(function (data) {

            $scope.sum = data.summ;
            $scope.suma = data.suma;
            $scope.discount_val = data.discount_val;
            $scope.reservation_sum = data.reservation;
            $scope.total_points = data.points;
            $scope.delivery.price = ($scope.delivery.free_shipping_prices[$scope.checkout_type] * 1 > $scope.sum * 1 ? $scope.delivery.shipping_prices[$scope.checkout_type] : 0 );
            console.log($scope.delivery.free_shipping_prices[$scope.checkout_type] * 1);

        }).error(function (data) {
            console.log("Error Calculate");
        });

        $scope.calculateWeightForShipping();

        $scope.checkReservations();
    }

    //Check if there is some reservated products
    $scope.checkReservations = function () {
        $scope.reservations = false;
        angular.forEach($scope.carts, function (obj) {
            if (obj.reservation)
                $scope.reservations = true;
        });
    };

    //Change quantity
    $scope.quantity = function (type, cart) {
        var del = true;
        switch (type) {
            case 'plus':
                var value = Number(cart.product.price_step) > 0 ? Number(cart.product.price_step) : 1;
                break;

            case 'minus':
                if (cart.count == 1) del = confirm(translate.deleteFromCart);
                var value = Number(cart.product.price_step) ? -Math.abs(Number(cart.product.price_step)) : -1;
                break;
        }


        if (del) {
            var inform = {
                _token: token,
                cart: cart
            };

            $http.post('/shop/cart/quantity/' + value + '/' + cart.id, inform).success(function (data) {

                $scope.carts = data.cart;
                $scope.$apply();
                //Show toastr message
                toastr[data.msg.type](data.msg.message, data.msg.title);

            }).error(function (data) {
                console.log("Error save");
            });

        }

    }
    //Change quantity2
    $scope.quantity2 = function (type) {
        switch (type) {
            case 'plus':
                var value = $scope.min;
                break;

            case 'minus':
                if ($scope.count == $scope.min) {
                    $scope.count =  $scope.min == 1 ? 2 : $scope.min;
                }
                var value = -Math.abs($scope.min);
                break;
        }

        $scope.count += value;
    }

    //Change quantity3
    $scope.quantity3 = function (type, id) {
        switch (type) {
            case 'plus':
                var value = 1;
                break;

            case 'minus':
                if ($scope[id] == 1) {
                    $scope[id] = 2;
                }
                ;
                var value = -1;
                break;
        }

        $scope[id] += value;
    }

    //Add to cart count 1
    $scope.add = function (value) {

        $http.post('/shop/cart/add/' + value, {categoryPage: 1}).success(function (data) {

            if (data.success == true) {
                toastr[data.msg.type](data.msg.message, data.msg.title);
                $scope.carts = data.cart;
            }
            else {
                toastr[data.msg.type](data.msg.message, data.msg.title);

                //After 1 sec redirect customer to product page
                $timeout(function () {
                    window.location.replace("/" + data.url);
                }, 500);
            }

        }).error(function (data) {
            console.log("add product to cart error");
        });

    };

    $scope.addGift = function (value, qty) {

        if (qty)
            var qty = $scope.count;
        else
            var qty = 1;

        //Options for this product
        var array = {
            options: $scope.options ? $scope.options : []
        };

        $http.post('/shop/cart/add/' + value + '/' + qty + '/true', array).success(function (data) {

            //Show toastr message
            toastr[data.msg.type](data.msg.message, data.msg.title);

            if (!data.success) {
                if (data.url) {
                    //After 1 sec redirect user
                    $timeout(function () {
                        window.location.replace("/" + data.url);
                    }, 1000);
                }
            } else {
                $scope.carts = data.cart;
            }

        }).error(function (data) {
            console.log('Add gift to cart error', data);
        });

    };

    //add to cart any count
    $scope.add2 = function (value) {
        var array = {
            options: $scope.options
        };

        $http.post('/shop/cart/add/' + value + '/' + $scope.count, array).success(function (data) {

            $scope.count = $scope.stepPrice ? Number($scope.stepPrice) : 1;

            $scope.options = false;

            //Show toastr message
            toastr[data.msg.type](data.msg.message, data.msg.title);
            $scope.carts = data.cart;
        }).error(function (data) {
            console.log("Error save");
        });
    };

    //add to cart any count
    $scope.add3 = function (value, relatedCount) {
        $http.post('/shop/cart/add/' + value + '/' + $scope[relatedCount]).success(function (data) {
            $scope[relatedCount] = 1;

            //Show toastr message
            toastr[data.msg.type](data.msg.message, data.msg.title);
            $scope.carts = data.cart;
        }).error(function (data) {
            console.log("Error save" + data);
        });
    };

    //Do product reservation
    $scope.addReservation = function (value) {
        if (confirm(translate.reservationConfirm)) {
            $http.post('/shop/cart/add/' + value, {categoryPage: 1, reservation: 1}).success(function (data) {

                if (data.success == true) {
                    toastr[data.msg.type](data.msg.message, data.msg.title);
                    $scope.carts = data.cart;
                }
                else {
                    toastr[data.msg.type](data.msg.message, data.msg.title);

                    //After 1 sec redirect customer to product page
                    $timeout(function () {
                        window.location.replace("/" + data.url);
                    }, 500);
                }

            }).error(function (data) {
                console.log("add product to cart error");
            });
        }
    };

    //Add reservation for this product
    $scope.add2Reservation = function (value) {
        if (confirm(translate.reservationConfirm)) {

            var array = {
                options: $scope.options,
                reservation: 1
            };

            $http.post('/shop/cart/add/' + value + '/' + $scope.count, array).success(function (data) {

                $scope.count = 1;

                $scope.options = false;

                //Show toastr message
                toastr[data.msg.type](data.msg.message, data.msg.title);
                $scope.carts = data.cart;

            }).error(function (data) {
                console.log("Error save");
            });

        }
    };

    //Delefe from cart
    $scope.delete = function (cart) {
        if (confirm(translate.deleteFromCart)) {
            var inform = {
                _token: token,
                cart: cart
            };

            $http.post('/shop/cart/delete/' + cart.id, inform).success(function (data) {

                $scope.carts = data.cart;
                $scope.$apply();
                //Show toastr message
                toastr[data.msg.type](data.msg.message, data.msg.title);


            }).error(function (data) {

                console.log("Error save");

            });
        }
    };

    //Check discount
    $scope.check = function () {

        if (typeof($scope.discount) !== "undefined" && $scope.discount.length > 0) {
            $http.get('/shop/cart/discount/' + $scope.discount).success(function (data) {

                if (data.type == 'true') {
                    $('#discount').removeClass('alert alert-danger');
                    $('#discount').addClass('alert alert-success');
                }
                else {
                    $('#discount').addClass('alert alert-danger');
                }
                $scope.calculate();

                if (data.msg)
                    toastr[data.msg.type](data.msg.message, data.msg.title);

            }).error(function (data) {

                console.log("Error save");

            });
        }
    };

    $scope.sendQueRequest = function (product_id) {

        if (typeof($scope.que) !== "undefined" && $scope.que.length > 0) {
            $http.get('/requests/add-to-product-que/' + $scope.que + '/' + product_id).success(function (data) {

                if (data == 'true') {
                    jQuery('#productQueMsg').removeClass('alert alert-danger');
                    jQuery('#productQueMsg').html(translate.productQueee);
                    jQuery('#productQueMsg').addClass('alert alert-success');
                    $scope.que = "";
                }
                else {
                    jQuery('#productQueMsg').html(translate.errorTryAgain);
                    jQuery('#productQueMsg').addClass('alert alert-danger');
                }

            }).error(function (data) {

                console.log("Error send request");

            });
        }
    };

    //User validation
    $scope.validateOrder = function () {

        var name = $scope.user.name;
        var surname = $scope.user.surname;
        var phoneNumber = $scope.user.phoneNumber;
        var email = $scope.user.email;
        var address = $scope.user.address;
        var postCode = $scope.user.postCode;
        var city = $scope.user.city;
        var noerror = true;

        if (name === undefined || name.length <= 0 || surname === undefined || surname.length <= 0
            || phoneNumber === undefined || phoneNumber.length <= 0
            || email === undefined || email.length <= 0
            || address === undefined || address.length <= 0
            || city === undefined || city.length <= 0
            || postCode === undefined || postCode.length <= 0) {
            toastr['error'](translate.fillInForm, translate.cartTitle);
            noerror = false;

            jQuery('.validate-line').each(function () {
                var object = jQuery(this);
                if (object.val().length == 0) {
                    object.addClass('error-line');
                }
                else {
                    if (!object.hasClass('email-line'))
                        object.removeClass('error-line');
                }

                object.keypress(function () {
                    object.removeClass('error-line');
                });

            });
        }

        var object = jQuery('.email-line');
        if (email === undefined || !validateEmail(email)) {
            toastr['error'](translate.emailWrong, translate.cartTitle);
            object.addClass('error-line');
            object.keypress(function () {
                object.removeClass('error-line');
            });
            noerror = false;
        }
        else {
            object.removeClass('error-line');
        }

        if ($scope.rules === undefined || $scope.rules === false) {
            toastr['error'](translate.acceptRules, translate.cartTitle);
            noerror = false;
        }

        switch ($scope.checkout_type) {
            case 'dpd':

                if (!$scope.city_dpd_id || $scope.city_dpd_id === '-1') {
                    toastr['error'](translate.chooseAddressPlease, translate.cartTitle);
                    noerror = false;
                }

                break;

            case 'omniva':

                if (!$scope.city_omniva_id || $scope.city_omniva_id === '-1') {
                    toastr['error'](translate.chooseAddressPlease, translate.cartTitle);
                    noerror = false;
                }

                break;

            case 'venipak':

                if (!$scope.city_venipak_id || $scope.city_venipak_id === '-1') {
                    toastr['error'](translate.chooseAddressPlease, translate.cartTitle);
                    noerror = false;
                }

                break;

        }

        //Check for registration...
        if ($scope.user.register) {
            //Test for password
            if ($scope.user.password1 === undefined || $scope.user.password1.length == 0 || !$scope.user.password2 === undefined || $scope.user.password1 !== $scope.user.password2) {
                toastr['error'](translate.passwordNotCorret, translate.registration);
                noerror = false;
            }

        }

        return noerror;

    };

    var emailTest = function () {
        //Test for email...
        $http.post('/shop/register/check-email', {_token: token, email: $scope.user.email}).success(function (data) {
            if (data.success === false) {
                toastr[data.msg.type](data.msg.message, data.msg.title);
            }
            else {
                $scope.complete();
            }
        }).error(function (data) {
            console.log('Error email check' + data);
        });
    };

    //Validate email
    var validateEmail = function (email) {
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    }

    //Finish order
    $scope.finishOrder = function () {

        if ($scope.validateOrder()) {
            if ($scope.user.register) {
                emailTest();
            }
            else {
                $scope.complete();
            }
        }
    };

    $scope.complete = function () {
        $scope.loader = false; //Start loader when sending data.

        var shipping = {};

        switch ($scope.checkout_type) {
            case 'dpd':

                shipping.type = 'dpd';
                shipping.address_id = $scope.city_dpd_id;

                break;

            case 'omniva':

                shipping.type = 'omniva';
                shipping.address_id = $scope.city_omniva_id;

                break;

            case 'venipak':

                shipping.type = 'venipak';
                shipping.address_id = $scope.city_venipak_id;

                break;

            case 'veikala':

                shipping.type = 'veikala';
                shipping.address_id = $scope.city_veikala_id;

                break;

            case 'kurjer':

                shipping.type = 'kurjer';

                break;
        }

        var inform = {
            _token: token,
            user: $scope.user,
            user_type: $scope.user.user_type,
            shipping: shipping,
            paymentMethod: $scope.payment_method,
            order_note: $scope.order_note
        };

        $http.post('/shop/order/finish-order', inform).success(function (data) {

            if (!data.msg && data.success == true) {
                window.location.replace(data.url);
            }
            else {
                $scope.loader = true;
                toastr[data.msg.type](data.msg.message, data.msg.title);
                $scope.calculate();
            }

        }).error(function (data) {
            console.log('Error in finishing order');
            console.log(data);
        });
    };

    $scope.validateStep = function (step) {

        jQuery.extend(jQuery.validator.messages, {
            required: translate.fieldRequired
        });

        var valid = false;

        var fields = {};

        if (step == 1) {
            fields = {
                first_name: {required: true},
                last_name: {required: true},
                phoneNumber: {required: true},
                email_address: {required: true, email: true},
                address: {required: true},
                index: {required: true},
                city: {required: true}
            };

            if ($scope.user.user_type == 1) {
                var company = {
                    title: {required: true},
                    regNr: {required: true}
                };
                fields = Object.assign(fields, company);
            }

            if($scope.user.register == 'register_user'){

                var password = {
                    newPassword : {
                        required: true
                    },
                    repeatNewPassword: {
                        equalTo : '#newPassword'
                    }
                };

                fields = Object.assign(fields, password);
            }
        }

        if(step == 2){
            switch ($scope.checkout_type) {
                case 'dpd':
                    if (!$scope.city_dpd_id || $scope.city_dpd_id === '-1'){
                        toastr['error'](translate.chooseAddressPlease, translate.cartTitle);
                    }else{
                        valid = true;
                    }
                    break;

                case 'omniva':
                    if (!$scope.city_omniva_id || $scope.city_omniva_id === '-1'){
                        toastr['error'](translate.chooseAddressPlease, translate.cartTitle);
                    }else{
                        valid = true;
                    }
                    break;
                case 'venipak':
                    if (!$scope.city_venipak_id || $scope.city_venipak_id === '-1'){
                        toastr['error'](translate.chooseAddressPlease, translate.cartTitle);
                    }else{
                        valid = true;
                    }
                    break;
                default:
                    valid = true;
                    break;
            }
            if(valid){
                $scope.step++;
                return ;
            }else{
                return ;
            }
        }

        valid = $('#validator').validate({
            rules: fields,
            messages: {
                email_address: {
                    email: 'Lūdzu ievadiet pareizu e-pastu.'
                }
            }
        });

        valid.form();

        if (valid.valid()) {
            $scope.step++;
        }
    };

    init();

}]);
